// AdBlock detection script. The file name is important - most ad blockers
// will block a file named ads.js and we want this to happen.
// Call DK.adBlockEnabled() (located in global.js) in order to have a nice
// easy boolean check to see if this was blocked or not.
// Taken from https://www.detectadblock.com/
(function () {
  let e = document.createElement('div');
  e.id = 'nDRHYAGxewuh';
  e.style.display = 'none';
  document.body.appendChild(e);
})();